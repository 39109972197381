
import SignIn from './pages/SignIn';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Dashboard from './pages/Dashboard';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';
import Engage from './pages/Engage/TalentDashbaord';
import Develop from './pages/Develop/Develop';
import Reward from './pages/Reward/Reward';
import SystemLinks from './pages/SystemLinks/SystemLinks';
import config from './config';
import TalentProfiles from './pages/TalentProfiles/TalentProfiles';
import TalentDashboard from './pages/TalentDashboard/TalentDashboard';
import TalentProfilesForm from './pages/TalentProfiles/TalentProfilesForm';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import IssueLogs from './pages/IssueLog/IssueLog';
import TalentGrid from "./pages/TalentProfiles/TalentGrid";

const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation,setConversation] = useState([]);
  const [question,setQuestion]= useState();
  const token = localStorage.getItem("token");
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = config.apiUrl;
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    // if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    // if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

const getConversation = ()=>{
  axios.get("getConversations").then((res)=>{
    setConversation(res.data.data)
  }).catch(()=>{

  })
}
const createQuestion = ()=>{
  axios.post("createQuestion",{question}).then((res)=>{
    getConversation();
    setQuestion("");
  }).catch(()=>{

  })
}


  return (
    <>
     
      {
 <button className="chatbot-toggler shadow ">
<span className="material-symbols-rounded">
  <div className="bot-icon-container" onClick={() => { setShowChatbot((val) => { return !val }) }}>
    {showChatbot && <i class="fa-solid fa-xmark text-light"></i>}
    {!showChatbot && <i class="fa-solid fa-message text-light"></i>}
  </div>
  {showChatbot && <div className='position-absolute chatbot-package shadow rounded-4'>
    <Chatbot
      data={chatbotData}
      getConversation={getConversation }
      conversation = {conversation}
      // onClose={()=>{ toggleBot(false)}}
      onChange = {(value)=>{ setQuestion(value)}}
      onClose={() => { }}
      onSubmit={() => { createQuestion() }}
    />
  </div>
  }
</span>
</button>
      }
      <BrowserRouter>
        { <Sidebar />}
        <Routes >
          <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
          <Route path="/talent-profiles" element={<ProtectedRoutes element={< TalentProfiles />} />} />
          <Route path="/talent-profiles/:id" element={<ProtectedRoutes element={< TalentProfilesForm />} />} />

          <Route path="/engage" element={<ProtectedRoutes element={< Engage />} />} />
          <Route path="/learn" element={<ProtectedRoutes element={< Develop />} />} />
          <Route path="/talent-dashboard" element={<ProtectedRoutes element={< TalentDashboard />} />} />
          <Route path="/reward" element={<ProtectedRoutes element={< Reward />} />} />
          <Route path="/system-links" element={<ProtectedRoutes element={< SystemLinks />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport/>} />} />
          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs/>} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/talent-grid" element={<ProtectedRoutes element={<TalentGrid/>} />} />
        </Routes>
        <Routes>
          {/* <Route path="/login" element={<Redirect element={< SignIn />} />} />           */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
