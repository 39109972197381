import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TempFilters from "../../components/Filters/TempFilters";

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const TalentDashboard = () => {
  const categories = ['Mentorship / Coaching', 'Active Training', 'Cross Training', 'On Job Training'];
  const data = [52, 23, 13, 12];
  const colors = ['#74BBC9', '#74BBC9', '#74BBC9', '#74BBC9'];

  const categories7 = ['Average Performers', 'Low Performers', 'High Performers'];
  const data7 = [48, 32, 20];
  const colors7 = ['#74BBC9', '#74BBC9', '#74BBC9', '#74BBC9', '#74BBC9'];


  const data2 = [70, 15, 5];
  const categories2 = ['High', 'Medium', 'Low'];
  const colors2 = ['#7A0200', '#CA6E2A', '#FFD530'];


  const data3 = [55, 20, 25];
  const categories3 = ['High', 'Medium', 'Low'];
  const colors3 = ['#7A0200', '#CA6E2A', '#FFD530'];

  const data4 = [60, 40];
  const categories4 = ['Yes', 'No'];
  const colors4 = ['#7A0200', '#CA6E2A']


  const data5 = [28, 47, 14, 7, 2];
  const categories5 = ['Leadership Talent', 'Specialist Talent', 'Core Talent', 'Emerging Talent', 'Group Talent'];
  const colors5 = ['#6C9BBC', '#6C9BBC', '#6C9BBC', '#6C9BBC', '#6C9BBC', '#6C9BBC', '#6C9BBC'];

  const data6 = [2, 7, 13, 7, 2, 47, 5, 12, 5];
  const categories6 = ['Lower Performer', 'Inconsistent Performer', 'Emerging Performer', 'Valued Contributor', 'Key Performer', 'High Professional', 'Rising Star', 'High Professional Plus', 'Top Talent'];
  const colors6 = ['#7A0200', '#CA6E2A', '#CA6E2A', "#FFD530", "#FFD530", "#FFD530", "#67904B", "#67904B", "#67904B"];



  const getOptions2 = (type, title, data, categories, colors) => ({
    chart: {
      type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: true
      }
    },
    colors: colors,
    title: {
      text: `${title}`,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: [
      {
        data: data,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },

      },
    ],
  });

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/Talent Dashboard"} />
      <main id="main" className="main">
        <TempFilters />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>

                <div className='card-body'>
                  <h5 className='fs-4'>Talent Risk of Leaving</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('column', '', data3, categories3, colors3)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='fs-4'>Talent Impact of Leaving</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('column', '', data2, categories2, colors2)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='fs-4'>Talent Successors Identified</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('column', 'Talent Successors Identified', data4, categories4, colors4)} />
                </div>
              </div>
            </div>


            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                <h5 className='fs-4'>Development Need</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('bar', '', data, categories, colors)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                <h5 className='fs-4'>Performance Analysis</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('bar', '', data7, categories7, colors7)} />
                </div>
              </div>
            </div>


            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                <h5 className='fs-4'>Talent Segments</h5>
                  <HighchartsReact highcharts={Highcharts} options={getOptions2('column', '', data5, categories5, colors5)} />
                </div>
              </div>
            </div>


            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                <h5 className='fs-4'>9-box Grid Talent Segments</h5>
                  <div className={"d-flex justify-content-center  scroll-x"}>
                    {/* <div className="chart-container"> */}
                      <HighchartsReact highcharts={Highcharts} options={getOptions2('column', '', data6, categories6, colors6)} />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default TalentDashboard;
