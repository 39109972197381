import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import img from "./home-image.jgp.png"

const Dashboard = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header label={<h5 className='py-0 my-0 font-weight-600'>2023 Talent Reviews</h5>} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-0'>

                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to the Talent Management & Succession system
                  </div>
                  <p>Talent Management is a process that aims to identify and put plans in plans for talent development in a structured manner. This process adds
                    substantial value to the business, increase staff retention, mobility, organisation performance and productivity.
                  </p>
                  <p>
                    Our Talent Management approach is defined by a Talent Framework that articulates what is defined as talent, how talent will be segmented
                    and the practices and organisational processes that entrench talent.
                  </p>
                  <p>
                    Regular talent reviews create an opportunity for talent dialogue, the outcomes of which are integrated into talent actions driven through collective
                    employee and manager accountability.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-4'>
                  Talent consists of: -
<br/>“…those individuals who can make a difference to organisational performance either through their immediate contribution or, in the longer-term, by demonstrating the highest levels of potential”
                  </div>
                  <img className='fluid-image rounded' src={img} />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-0'>


                  <p>
                    When reviewing talent, use the <span className='link-container'><a href="/files/TalentManagementGuidelines.pdf" download>questions provided</a></span> to guide your thinking about the individual as you complete the template. The questions
                    are designed to probe for a deeper understanding of each person’s natural talents and strengths and where they would best flourish within
                    our organisation.
                  </p>
                  <p>Remember it is not about the template, it is about the individual!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default Dashboard;
