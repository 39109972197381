import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import img from "./profile-image.png"
import { Button, Modal } from 'react-bootstrap';
import EmployeeInformation from './EmployeeInformation';

const TalentProfilesForm = () => {

  const [data, setData] = useState(EmployeeInformation);
  const [selectedKey, setSelectedKey] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  var [values, setValues] = useState({
    emergency_1: { value: "Maxwell Mphioe", id: -1 },
    emergency_2: { value: "", id: -1 },
    readyNow_1: { value: "", id: -1 },
    readyNow_2: { value: "", id: -1 },
    ready1_2_years_1: { value: "", id: -1 },
    ready1_2_years_2: { value: "", id: -1 },
    ready3_5_years_1: { value: "", id: -1 },
    ready3_5_years_2: { value: "", id: -1 },
  })
  const openModal = (key) => {
    setSelectedKey(key)
    setShowModal(true)
    setFilteredItems(data);
  };
  const closeModal = () => {
    setShowModal(false)
  }

  const onSelectEmployee = (object) => {
    if (object.id == values[selectedKey]?.id) {
      setValues({ ...values, [selectedKey]: { id: -1, value: "" } })
    } else {
      setValues({ ...values, [selectedKey]: { id: object.id, value: object.Name + " " + object.Last_Name } })
    }
  }

  const handleSearchInput = (event) => {
    const query = event.target.value;

    // Filter the items based on the search query
    const filteredResult = filteredItems.filter((item) => {
      // Check if any of the values in the item object contain the search query
      for (const key in item) {
        const value = String(item[key]); // Convert the value to a string
        if (value.toLowerCase().includes(query.toLowerCase())) {
          return true; // Include the item if any value matches the query
        }
      }
      return false; // Exclude the item if no value matches the query
    });
    if (query == "") {
      setFilteredItems(data);
    } else {
      setFilteredItems(filteredResult);
    }
  };

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/My Talent Profile"} />
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card mt-3'>

                <div className='card-body'>
                  <form>

                    <div className='row'>
                      <div className='col-md-2'>
                        <div className="card border p-1 round" >
                          <img src="/assets/images/profile-image.jpg" className="card-img-top rounded-3" style={{ borderRadius: "50px" }} alt="..." />
                          <div className="card-body">
                            <div class="form-group">
                              <label for="exampleInputEmail1 text-center" className='mb-0'>Full Name:</label>
                              <div className='ms-1'>Mandy Norway</div>
                            </div>

                            {/* <div class="form-group">
                              <span  className='fw-bold'>Full Name:</span>
                              <div className='ms-'>Mandy Norway</div> */}

                            {/* <input disabled type='text' value="Mandy Norway" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" /> */}
                            {/* </div> */}

                          </div>
                        </div>
                      </div>
                      <div className='col-md-10'>
                        <div className='row mb-4'>

                          <div className='col-md-4'>
                            <div class="form-group">
                              <label for="exampleFormControlSelect1">Reports To</label>
                              <input disabled class="form-control" id="exampleFormControlSelect1" value="Marilyn Thomas" />
                            </div>
                          </div>

                          <div className='col-md-4 mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Current Role</label>
                              <input disabled value="Head: Corporate Finance" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1"> Date Of Birth</label>
                              <input disabled value={"18 Mar 1987"} type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Gender</label>
                              <input disabled value="Female" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Race</label>
                              <input disabled value="Coloured" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Business Unit</label>
                              <input disabled value={"Finance"} type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Previous Role</label>
                              <input disabled value='senior Specialist: Finance' type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>
                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Level</label>
                              <input disabled value="Middle Management" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Qualifications</label>
                              <input disabled value="Degree: Finance" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Previous Employer</label>
                              <input disabled value={"Concise Flow"} type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Start Date - Company</label>
                              <input disabled value="1 Oct 2004" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>
                          <div className='col-md-4  mb-3'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Start Date - Role</label>
                              <input disabled value="1 May 2019" type='text' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                          </div>

                          <div className='col-md-12'>
                            <div class="form-group">
                              <label for="exampleInputEmail1">Performance History</label>
                              <div disabled class="border rounded p-2 disabled" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                <pre>
                                  Y1:  Fully Achieved
                                  <br />
                                  Y2:  Fully Achieved
                                  <br />
                                  Y3:  Fully Achieved
                                  <br />
                                </pre>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>


                    </div>

                    <br />

                    <div className='row'>

                      <div className='col-md-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Specialist</label>
                          <select value={2} class="form-select" id="exampleFormControlSelect1">
                            <option disabled >Select</option>
                            <option value={1}>Specialist</option>
                            <option value={2}>General Management</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Mobility</label>
                          <select value={2} class="form-select" id="exampleFormControlSelect1">
                            <option disabled >Select</option>
                            <option value={1}>Functional</option>
                            <option value={2}>Regional</option>

                          </select>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Location</label>
                          <select value={1} class="form-select" id="exampleFormControlSelect1">
                            <option disabled >Select</option>
                            <option value={1}>Gauteng</option>
                            <option value={2}>Rest of South Africa</option>
                            <option value={3}>Rest of Africa</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Sustained Performance</label>
                          <select value={3} class="form-select" id="exampleFormControlSelect1">
                            <option disabled >Select</option>
                            <option value={1}>Low</option>
                            <option value={2}> Medium</option>
                            <option value={3}>High</option>

                          </select>
                        </div>
                      </div>


                      <div className='col-md-6 mt-3'>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Career Milestones (What key milestones have they achieved
                            at previous employers)</label>
                          <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >

                          </textarea>
                        </div>
                      </div>

                      <div className='col-md-6 mt-3'>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Career Aspirations (What are the individual’s career ambitions?
                            / What are they working towards?)</label>
                          <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >

                          </textarea>
                        </div>
                      </div>

                      <div className='col-md-6 mt-3'>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Performance (How have they achieved performance?
                            Include associated behaviours)</label>
                          <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >

                          </textarea>
                        </div>
                      </div>

                      <div className='col-md-6 mt-3'>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Potential (Specific observations - ability, attitude, aspirations.
                            Could they possibly move 1 / 2 / 3 levels up?)</label>
                          <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >

                          </textarea>
                        </div>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Highly Valued Skills</label>
                          <textarea type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >

                          </textarea>
                        </div>
                      </div>


                      <div className='col-md-4 mt-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Potential Rating</label>
                          <select class="form-select" id="exampleFormControlSelect1">
                            <option ></option>
                            <option value={1}>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>


                          </select>
                        </div>
                      </div>

                      <div className='col-md-4 mt-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Risk of leaving</label>
                          <select class="form-select" id="exampleFormControlSelect1">
                            <option ></option>

                            <option value={1}>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-md-4 mt-3'>
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">Impact of leaving</label>
                          <select class="form-select" id="exampleFormControlSelect1">
                            <option  ></option>
                            <option value={1}>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <br />

                  <div className='d-flex flex-column mt-4'>
                    <div className='d-flex text-light' >
                      <div className='flex-grow-1 d-flex flex-column bg-secondary p-1 border'>
                        <div className='d-flex font-weight-600'>Leadership Talent <input className='ms-auto' type="radio" name="group1" /></div>
                        <p>
                          They drive & shape business
                          strategy
                        </p>
                      </div>

                      <div className='flex-grow-1 d-flex flex-column bg-tertiary p-1 border'>
                        <div className='d-flex font-weight-600'>Specialist Talent<input className='ms-auto' checked type="radio" name="group1" /></div>
                        <p>
                          Considered experts in their field of work
                        </p>
                      </div>
                      <div className='flex-grow-1 d-flex flex-column bg-secondary-alt p-1 border'>
                        <div className='d-flex font-weight-600'>Core Talent <input className='ms-auto' type="radio" name="group1" /></div>
                        <p>
                          Individuals with depth of experience and knowledge
                        </p>
                      </div>
                      <div className='flex-grow-1 d-flex flex-column bg-tertiary-alt p-1 border '>
                        <div className='d-flex font-weight-600'>Emerging Talent<input className='ms-auto' type="radio" name="group1" /></div>
                        <p>
                          individuals with high aspirations and drive
                        </p>
                      </div>
                      <div className='flex-grow-1 d-flex flex-column bg-tertiary-alt-2 p-1 border'>
                        <div className='d-flex font-weight-600'>Group Talent <input className='ms-auto' type="radio" name="group1" /></div>
                        <p>
                          Could potentially fiulfil critical roles
                        </p>
                      </div>
                    </div>
                    <br />
                    <br />

                    <div className='d-flex flex-column tett-light' >

                      <div className='row  text-light'>
                        <div className='col-4 border p-2  bg-orange'>
                          <div className='font-weight-600 d-flex'>Emerging Performer <input className='ms-auto' type="radio" name="group2" /></div>
                          Has the potential to perform exceptionally
                          well, but without the ability to play out the
                          potential is in the future.
                        </div>
                        <div className='col-4 border p-2  bg-green'>
                          <div className='font-weight-600 d-flex'> Rising Star <input className='ms-auto' type="radio" name="group2" /> </div>

                          Consistently meets and occasionally
                          exceeds expectations. Has capacity for
                          different challenges on a consistent basis.
                        </div>
                        <div className='col-4 border p-2  bg-green'>
                          <div className='font-weight-600 d-flex'> Top Talent <input className='ms-auto' type="radio" name="group2" /> </div>
                          The best talent you have; typically in short
                          supply but high demand.
                        </div>
                      </div>

                      <div className='row text-light'>
                        <div className='col-4 border p-2  bg-orange text-light'>
                          <div className='font-weight-600 d-flex'>  Inconsistent Performer <input className='ms-auto' type="radio" name="group2" /> </div>
                          Is a valued contributor with potential but is
                          not meeting performance expectations in
                          current role.
                        </div>
                        <div className='col-4 border p-2  bg-yellow tex-light'>
                          <div className='font-weight-600 d-flex'>  Key Performer <input className='ms-auto' checked type="radio" name="group2" /> </div>
                          A valued member of the team. Consistently
                          meets and occasionally exceeds
                          expectations.
                        </div>
                        <div className="col-4 border p-2  bg-green">
                          <div className='font-weight-600 d-flex'>  High Professional Plus <input className='ms-auto' type="radio" name="group2" /> </div>
                          Consistently produces exceptional results
                          and receives high performance ratings.
                        </div>
                      </div>
                      <div className='row text-light'>
                        <div className='col-4 border p-2  bg-brown'>
                          <div className='font-weight-600 d-flex'>  Lower Performer <input className='ms-auto' type="radio" name="group2" /> </div>
                          Is not delivering results as expected and
                          cannot effectively adapt to new and
                          different situations.
                        </div>
                        <div className='col-4 border p-2  bg-yellow'>
                          <div className='font-weight-600 d-flex'>  Valued Contributor <input className='ms-auto' type="radio" name="group2" /> </div>
                          A valued member of the team. Consistently
                          meets and occasionally exceeds
                          expectations.
                        </div>
                        <div className='col-4 border p-2  bg-yellow'>
                          <div className='font-weight-600 d-flex '>  High Professional <input className='ms-auto' type="radio" name="group2" /> </div>
                          Consistently produces exceptional results.
                          Highly valued technical/functional skills
                          in their area of expertise.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-column mt-4'>
                    <div className='row'>
                      <div className='col-md-4 d-flex flex-column'>
                        <label>Strengths</label>
                        <input value="People Management and leadership skills" type="text" className='form-control mb-3' />
                        <input type="text" className='form-control' />
                      </div>

                      <div className='col-md-4 d-flex flex-column'>
                        <label>Development Needs</label>
                        <input value="MBA" type="text" className='form-control mb-3' />
                        <input type="text" className='form-control' />
                      </div>

                      <div className='col-md-4 d-flex flex-column'>
                        <label>Development Actions</label>
                        <input value={"Register in 2024"} type="text" className='form-control mb-3' />
                        <input type="text" className='form-control' />
                      </div>


                    </div>

                  </div>

                  <div className='d-flex flex-column mt-3'>
                    <div className='bg-secondary p-2 text-center text-light font-weight-600 mb-3 mt-3'>Individual Development Planning</div>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column'>
                        <label>Next Posible Role</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input defaultValue="Divisional Director" className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Readiness</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <select value={1} class="form-select flex-grow-1 mx-2 border rounded p-2 mt-2" id="exampleFormControlSelect1">
                            <option  ></option>
                            <option value={1}>Ready Now</option>
                            <option value={2}>Ready Soon (1 - 3 yrs)</option>
                            <option value={3}>Ready Future (3 - 5 yrs)</option>
                          </select>
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <select value={1} class="form-select flex-grow-1 mx-2 border rounded p-2 mt-2" id="exampleFormControlSelect1">
                            <option  ></option>
                            <option value={1}>Ready Now</option>
                            <option value={2}>Ready Soon (1 - 3 yrs)</option>
                            <option value={3}>Ready Future (3 - 5 yrs)</option>
                          </select>
                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Development to Ready</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />

                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />

                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Fit to (to culture)</label>
                        <textarea type="text" className='form-control mt-1' >

                        </textarea>

                      </div>

                    </div>
                  </div>

                  <div className='d-flex flex-column mt-4'>
                    <div className='bg-secondary p-2 text-center text-light font-weight-600 my-3'>Succession planning (For this role)</div>
                    <div className='row'>
                      <div className='col-md-3 d-flex flex-column'>
                        <label>Emergency</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input value={values.emergency_1.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("emergency_1") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input value={values.emergency_2.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("emergency_2") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Ready Now</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input value={values.readyNow_1.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("readyNow_1") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input value={values.readyNow_2.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("readyNow_2") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Ready 1 - 2 years</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input value={values.ready1_2_years_1.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("ready1_2_years_1") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input value={values.ready1_2_years_2.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("ready1_2_years_2") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                      </div>

                      <div className='col-md-3 d-flex flex-column'>
                        <label>Ready 3 - 5 years</label>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>1)</span>
                          <input value={values.ready3_5_years_1.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("ready3_5_years_1") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                        <div className='d-flex'>
                          <span className='pt-3 pe-2'>2)</span>
                          <input value={values.ready3_5_years_2.value} className='ms-auto flex-grow-1 mx-2 border rounded p-2 mt-2' />
                          <span className='icon-search pt-3' onClick={() => { openModal("ready3_5_years_2") }}>
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='d-flex'>
                  <button className='btn btn-sm py-1 bg-secondary m-3 ms-auto mb-3 text-light'>SAVE</button>
                </div>

              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            Employees
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Items"
              onChange={handleSearchInput}
            />
          </div>

          <>
            <table class="table table-hover table-striped instance-table-header custom-table ">
              <thead>
                <tr>

                  <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                  <th width="" scope="col" className="" id="subject">Name</th>
                  <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                  <th width="" scope="col" className="" id="date_opened">Position</th>
                  <th width="" scope="col" className="" id="date_closed">Line Manager</th>
                  <th width="" scope="col" className=" m-0" id="issue_sub_category">Action</th>

                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, key) => (
                  <>
                    <tr>
                      <td >{item.Emp_Number}</td>
                      <td>{item.Name}</td>
                      <td>{item.Last_Name}</td>
                      <td >{item.Position}</td>
                      <td >{item.Line_Manager}</td>
                      <td>
                        <button className={`btn btn-sm btn-primary ${values[selectedKey] && item.id == values[selectedKey].id && " btn-secondary"}`} onClick={() => { onSelectEmployee(item) }}>select</button>
                      </td>

                    </tr>
                  </>
                ))}


              </tbody>
            </table>

          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
export default TalentProfilesForm;
