import Dropdown from "../DropDown/Dropdown";
import React from "react";


const TempFilters = ({handleFilterChange = () =>{}}) => {
    const handleChange = (selected) => {
        handleFilterChange(selected)
    }
    return (
        <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
            <div className="m-0 my-1 d-flex">
                <div className=" d-flex flex-wrap">
                    <Dropdown handleChange={handleChange} label={"Race"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "African" },
                        { id: 2, label: "Indian" },
                        { id: 3, label: "Coloured" },
                        { id: 4, label: "White" }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Age Group"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: '18-24' },
                        { id: 2, label: '25-29' },
                        { id: 3, label: '30-39' },
                        { id: 4, label: '40-49' },
                        { id: 5, label: '50-59' },
                        { id: 6, label: '60+' }
                    ]} />

                    <Dropdown handleChange={handleChange} label={"Business Area"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 3, label: "Acturial & Risk" },
                        { id: 4, label: "Asset Management/Institutional" },
                        { id: 5, label: "Business Development" },
                        { id: 6, label: "Client & Advisor Experience" },
                        { id: 7, label: "Client & Advisor Service Centre" },
                        { id: 8, label: "Employee Benefits" }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Business Unit"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "CLIENT & ADVISER SERVICE CENTRE" },
                        { id: 2, label: "CLIENT & ADVISER EXPERIENCE" },
                        { id: 3, label: "CLIENT & ADVISER SERVICE CENTRE" },
                        { id: 4, label: "RETAIL RISK SOLUTIONS" },
                        { id: 5, label: "INFORMATION TECHNOLOGY" }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Division"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: 'Risk Product' },
                        { id: 2, label: 'Operations' },
                        { id: 3, label: 'Chapters' },
                        { id: 4, label: 'Onboarding' },
                        { id: 5, label: 'Service Channel Management' }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Department"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: 'Product Pricing' },
                        { id: 2, label: 'Fund Governance & New Business' },
                        { id: 3, label: 'Analyst Chapter' },
                        { id: 4, label: 'Onboarding Admin & Medical Services' },
                        { id: 5, label: 'Business Preservation' }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Line Manager"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "Ayanda Grace" },
                        { id: 2, label: "Grace Bryston" },
                        { id: 3, label: "Maxwell Chrisbrown" },
                        { id: 4, label: "Nozuko Gym" },
                        { id: 5, label: "Emmanuel Johncena" }
                    ]} />

                    <Dropdown handleChange={handleChange} label={"Country"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "South Africa" },
                        { id: 2, label: "Swaziland" },
                        { id: 3, label: "Lesotho" },
                        { id: 4, label: "Namibia" },
                        ]} />

                    <Dropdown handleChange={handleChange} label={"Gender"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "Female" },
                        { id: 2, label: "Male" }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Yrs Of Services"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: '0-2' },
                        { id: 2, label: '3-5' },
                        { id: 3, label: '6-10' },
                        { id: 4, label: '11-20' },
                        { id: 5, label: '21-30' },
                        { id: 6, label: '31+' }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"EE Category"} myClass={"mx-2 my-3"} filterItems={[{ id: 1, label: "Top Management" },
                    { id: 2, label: "Senior Management" },
                    { id: 3, label: "Professional/ Middle Management" },
                    { id: 4, label: "Technical/ Juniour Management" },
                    { id: 5, label: "Semi-Skilled" }]} />
                    <Dropdown label={"Disability"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "Yes" },
                        { id: 2, label: "No" }
                    ]} />

                    <Dropdown handleChange={handleChange} label={"Band"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "B1" },
                        { id: 2, label: "B2" },
                        { id: 3, label: "B3" },
                        { id: 4, label: "B4" },
                        { id: 5, label: "B5" }
                    ]} />
                    <Dropdown handleChange={handleChange} label={"Pat Grade"} myClass={"mx-2 my-3"} filterItems={[
                        { id: 1, label: "A1-A2" },
                        { id: 2, label: "B1-B2" },
                        { id: 3, label: "C2-C3" },
                        { id: 4, label: "D4-D5" },
                        { id: 5, label: "E2-E3" }
                    ]} />
                </div>
            </div>
        </div>
    );
}
export default TempFilters