import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import { useNavigate } from 'react-router-dom';

const unfiltered = {
  A:{
    title:"3 - Emerging Performer",
    subTitle: "Manage closely & develop with pace",
    data: ['Zakhele Smith','Dans Ledwaba','Mill Abraham','Adriaan Will','Jack Taylor'],
    totals: [22, 48, 11],
    bg:'bg-orange',
  },
  B:{
    title:"6 - Rising Star",
    subTitle: "Stretch & develop",
    data: ['Lerato Godwin','Frans Mans','Donald Smith','Jack Misson','Danny Sam'],
    totals: [38, 48, 14],
    bg:'bg-success',
  },
  C:{
    title:"9 - Top Talent",
    subTitle: "Invest focus & accelerate",
    data: ['Abraham Jones','Green Marylyn','Arnold Zikhele','Adele Haines','Anita Jones'],
    totals: [59, 47, 11],
    bg:'bg-success',
  },
  D:{
    title:"2 - Inconsistent Performer",
    subTitle: "Manage closely",
    data: ['Abram Micken','Alice Brigette','Mill Maiden','Peace Worldin','Jack Perry'],
    totals: [51, 46, 12],
    bg:'bg-orange',
  },
  E:{
    title:"5 - Key Performer",
    subTitle: "Continue to develop",
    data: ['Zodwa Taylor','Zanele Zimsa','Nick Marion','Danny Peterson','Steve Sevens'],
    totals: [66, 52, 13],
    bg:'bg-yellow',
  },
  F:{
    title:"8 - High Professional Plus",
    subTitle: "Stretch & accelerate",
    data: ['Tsholo Maiden','Bridget Alison','Tshego Snoven','Nel Peters','Adrian Wooden'],
    totals: [57, 47, 6],
    bg:'bg-success',
  },
  G:{
    title:"1 - Low Performer",
    subTitle: "Improve or Exit",
    data: ['Simon Simone','Pretty Jones','Donald Duck','Peace Masheru','Rachel Perry'],
    totals: [38, 48, 14],
    bg:'bg-danger',
  },
  H:{
    title:"4 - Valued Contributor",
    subTitle: "Manage to stronger performance",
    data: ['Nundy Pedimol','Jack Sloane','Mickey Half','Adam San','Alice Wonderland'],
    totals: [34, 43, 10],
    bg:'bg-yellow',
  },
  I:{
    title:"7 - High Professional",
    subTitle: "Focus on retention & motivation",
    data: ['Maude Bill','Jack Deverall','Cindy Bettel','Lewis Oackmore','Alice Petro'],
    totals: [51, 47, 16],
    bg:'bg-yellow',
  },
}


const filtered = {
      A:{
        title:"3 - Emerging Performer",
        subTitle: "Manage closely & develop with pace",
        data: ['Zakhele Smith','Dans Ledwaba','Mill Abraham','Adriaan Will'],
        totals: [7, 14, 21],
        bg:'bg-orange',
      },
      B:{
        title:"6 - Rising Star",
        subTitle: "Stretch & develop",
        data: ['Danny Sam','Jack Misson'],
        totals: [0, 0, 11],
        bg:'bg-success',
      },
      C:{
        title:"9 - Top Talent",
        subTitle: "Invest focus & accelerate",
        data: ['Anita Jones'],
        totals: [0, 100, 5],
        bg:'bg-success',
      },
      D:{
        title:"2 - Inconsistent Performer",
        subTitle: "Manage closely",
        data: ['Mill Maiden','Peace Worldin',],
        totals: [51, 46, 1],
        bg:'bg-orange',
      },
      E:{
        title:"5 - Key Performer",
        subTitle: "Continue to develop",
        data: [],
        totals: [0, 0, 0],
        bg:'bg-yellow',
      },
      F:{
        title:"8 - High Professional Plus",
        subTitle: "Stretch & accelerate",
        data: [],
        totals: [0, 0, 0],
        bg:'bg-success',
      },
      G:{
        title:"1 - Low Performer",
        subTitle: "Improve or Exit",
        data: ['Simon Simone','Rachel Perry','Donald Duck','Peace Masheru'],
        totals: [58, 55, 21],
        bg:'bg-danger',
      },
      H:{
        title:"4 - Valued Contributor",
        subTitle: "Manage to stronger performance",
        data: ['Nundy Pedimol','Jack Sloane','Minnie Sekgame',],
        totals: [33, 33, 16],
        bg:'bg-yellow',
      },
      I:{
        title:"7 - High Professional",
        subTitle: "Focus on retention & motivation",
        data: ['Maude Bill','Cindy Bettel'],
        totals: [50, 100, 11],
        bg:'bg-yellow',
      },
    }

const TalentGrid = () => {
  const [toggle, setToggle] = useState(false);
  const [object, setObject] = useState(unfiltered);
  const navigation = useNavigate();

  const handleFilterChange = () => {
    setToggle(!toggle);
  }

  useEffect(()=>{
    let obj = toggle? filtered:unfiltered;
    setObject(obj)
  },[toggle])

  const gridBlock = (object, block) => {
    if (block === undefined){
      return  (<div>block undefined</div>);
    }

    return (
        <>
          <div className={`bg-orange p-2 position-relative ${object[block].bg}`}>
            <div className="d-flex flex-column align-items-center bg-gray-light border">
              <span className="text-black">{object[block].title}</span>
              <span className="text-black">{object[block].subTitle}</span>
            </div>
            <div className="scrollable-div">
              <ul className="list-group mt-2">
                {object[block].data.map((item, key)=>(
                    <li className="mb-2 me-2">
                      <div className="bg-gray-light border px-2 py-2">
                        <span className="text-black">{item}</span>
                      </div>
                    </li>
                ))}

              </ul>
            </div>
            <div className="square-div d-flex flex-column px-1">
              <span className="text-primary">{`${object[block].totals[0]}% EE`} </span>
              <span className="text-primary">{`${object[block].totals[1]}% Female`} </span>
              <span className="text-primary">{`${object[block].totals[2]}% Total`} </span>
            </div>
          </div>
        </>
    );
  }

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/Talent Grid"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn bg-primary btn-sm py-1 me-2 text-light' onClick={()=>{setToggle(false)}}>Reset</button>
            <button className='btn bg-primary btn-sm py-1 text-light'>REAPPLY</button>

          </div>
          <div>
            <TempFilters handleFilterChange={handleFilterChange}/>

          </div>
        </div>
        <section className="section ">
          <div className="card">
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className='row'>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "A")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "B")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "C")}
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "D")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "E")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "F")}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "G")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "H")}
                    </div>
                    <div className='col-4 p-2'>
                      {gridBlock(object, "I")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default TalentGrid;
