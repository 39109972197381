import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import { useNavigate } from 'react-router-dom';

const TalentProfiles = () => {

const navigation = useNavigate()

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/Talent Profiles"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn bg-primary btn-sm py-1 me-2 text-light'>Reset</button>
            <button className='btn bg-primary btn-sm py-1 text-light'>REAPPLY</button>

          </div>
          <div>
            <TempFilters />

          </div>
        </div>
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card mt-3'>

                <div className='card-body'>
                  <table class="table  custom-table">
                    <thead>
                      <tr>
                        <th scope="col">Personnel #</th>
                        <th scope="col">Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Position Title</th>
                        <th scope="col">Line Manager</th>
                        <th scope="col">Open Talent Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >111222</td>
                        <td>Joe</td>
                        <td>Slovo</td>
                        <td>Head: Risk Management</td>
                        <td>Promise Jones</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>

                      <tr>
                        <td >999333</td>
                        <td>Anele</td>
                        <td>Swanepoel</td>
                        <td>Executive: Risk Solutions</td>
                        <td>William May</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>

                      <tr>
                        <td>767676</td>
                        <td>Sam</td>
                        <td>Smith</td>
                        <td>Chief Spec: Product Marketing</td>
                        <td>Mary Snow</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>


                      <tr>
                        <td >444222</td>
                        <td>Nick</td>
                        <td>White</td>
                        <td>Senior Specialist: Product Owner</td>
                        <td>Henk Joe</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>

                      <tr>
                        <td >878787</td>
                        <td>Wendy</td>
                        <td>Ross</td>
                        <td>Programme Manager: Strategic Projects</td>
                        <td>Sammy Marks</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>

                      <tr>
                        <td >101010</td>
                        <td>Mandy</td>
                        <td>Norway</td>
                        <td>Head: Corporate Finance</td>
                        <td>Marlyn Thomas</td>
                        <td onClick={()=>{navigation("/talent-profiles/1")}}><i class="fa-solid fa-folder-open" onClick={()=>{navigation("/talent-profiles/1")}}></i></td>
                      </tr>

                      <tr>
                        <td >32323</td>
                        <td>Jack</td>
                        <td>Jones</td>
                        <td>Divisional Executive: Channel Support</td>
                        <td>Joel Smoth</td>
                        <td><i class="fa-solid fa-folder-open"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default TalentProfiles;
